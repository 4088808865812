import { makeAutoObservable, runInAction } from "mobx";
import * as api from "../api";

class MyMembersStore {

    marketplaceId = null
    sellerId = null
    endUserSeasonTickets = []
    loading = true

    constructor() {
        makeAutoObservable(this)
    }

    get members() {
        // unique members based on endUserId
        return Array.from(new Set(this.endUserSeasonTickets.map(ticket => ticket.endUserId)))
            .map(endUserId => this.endUserSeasonTickets.find(ticket => ticket.endUserId === endUserId))
    }

    async fetchData() {
        this.loading = true
        try {
            const response = await api.getMyMembers({ marketplaceId: this.marketplaceId, sellerId: this.sellerId, })
            debugger
            runInAction(() => {
                this.endUserSeasonTickets = response.data
                    .map(x => {
                        const { _id, name, endUser, status, createdAt, cancelDate, iterationsCompleted, price, hrc, startDate, comment } = x
                        const { email = "", fname = "", lname = "", phone = "" } = endUser || {}
                        return {
                            _id,
                            endUserId: endUser?._id,
                            fullName: `${fname} ${lname}`,
                            phone,
                            email,
                            createdAt,
                            cancelDate,
                            startDate,
                            iterationsCompleted,
                            price,
                            status,
                            name,
                            hrc,
                            comment
                        }
                    }).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            })
        } catch (error) {
            console.error(error)
            throw error
        } finally {
            this.loading = false
        }
    }

    async refreshData() {
        const response = await api.getMyMembers({ marketplaceId: this.marketplaceId, sellerId: this.sellerId, })
        runInAction(() => {
            this.endUserSeasonTickets = response.data
        })
    }

    init(marketplaceId, sellerId) {
        if (this.endUserSeasonTickets.length) {
            return
        }
        this.marketplaceId = marketplaceId
        this.sellerId = sellerId
        return this.fetchData()
    }

    // Computed field that reduces all endUserSeasonTickets names
    get subscriptionNames() {
        return Array.from(new Set(this.endUserSeasonTickets.map(ticket => ticket.name)));
    }
}

export default new MyMembersStore();